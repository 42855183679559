import { PurchaseParams } from './types';

const SegmentHub = {
  pageView: async function () {
    const location = window.location;
    const searchParams = new URLSearchParams(location.search);
    const referrer = document.referrer;
    // Delete referrer value after first page
    Object.defineProperty(document, 'referrer', { value: '' });

    const payload = {
      // session_id: session_id,
      page_title: document.title,
      page_hostname: location.hostname,
      page_path: location.pathname,
      page_location: location.href,
      user_agent: navigator.userAgent,
      page_referrer: referrer,
      ...(searchParams.get('utm_campaign') && { campaign: searchParams.get('utm_campaign') }),
      ...(searchParams.get('utm_source') && { source: searchParams.get('utm_source') }),
      ...(searchParams.get('utm_medium') && { medium: searchParams.get('utm_medium') }),
      ...(searchParams.get('utm_term') && { term: searchParams.get('utm_term') }),
      ...(searchParams.get('utm_content') && { content: searchParams.get('utm_content') }),
    };

    await fetch('/api/segmenthub/page_view', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    return;
  },

  purchase: async function (params: PurchaseParams) {
    const payload = {
      currency: 'TRY',
      transaction_id: params.transactionId,
      value: params.revenue,
      items: [
        {
          item_id: params.product.id,
          item_name: params.product.name,
          currency: 'TRY',
          index: params.product.position,
          item_brand: params.product.brand,
          item_category: params.product.category,
          item_list_name: params.list,
          ...(params.product.variant && { item_variant: params.product.variant }),
          price: params.product.price,
          quantity: 1,
        },
      ],
    };

    await fetch('/api/segmenthub/purchase', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    return;
  },

  purchaseNative: async function (id: number) {
    await fetch('/api/segmenthub/purchase_native', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id }),
    });

    return;
  },
};

export default SegmentHub;
