import { getCookiesClient } from '@/src/utils/cookie-parser';
import {
  ActionFieldPurchase,
  IDatalayerCustomEvent,
  IDatalayerPageDetails,
  IDatalayerUserInfo,
  PageDetails,
  PIPELINE_NAME,
  Purchase,
  PurchaseProduct,
  SERVER_CODE,
  Slider,
  VERSION,
} from './types';

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};
declare const window: WindowWithDataLayer;

interface IEventData {
  toDataLayerObject(): any;

  push(): void;
}

abstract class DataLayerEvent implements IEventData {
  abstract toDataLayerObject(): any;

  push() {
    typeof window !== 'undefined' && window?.dataLayer?.push(this.toDataLayerObject());
  }
}

class OriginalLocationEvent extends DataLayerEvent {
  constructor(private url: string) {
    super();
  }

  toDataLayerObject() {
    return {
      event: 'originalLocation',
      originalLocation: this.url,
    };
  }
}

class PageDetailsEvent extends DataLayerEvent {
  constructor(private pageDetails: PageDetails) {
    super();
  }

  toDataLayerObject() {
    const { pageData, clientData, type } = this.pageDetails;

    const virtualPageUrl =
      pageData.virtualPageUrl ||
      (() => {
        if (typeof window !== 'undefined') {
          return `${window.location.pathname}${window.location.search}`;
        }
        return '';
      })();

    const userSessionCookie = getCookiesClient()?.session;
    let hkMemberId = 'NULL';
    let hkSha256Email = 'NULL';
    let loginState = false;

    if (userSessionCookie) {
      try {
        const parsedSessionCookie = JSON.parse(userSessionCookie);
        const userInfo = parsedSessionCookie?.userInfo;
        if (userInfo) {
          hkMemberId = userInfo.userId || 'NULL';
          hkSha256Email = userInfo.emailToSha256 || 'NULL';
          // TODO: loginState may not always reflect the truth, BE check must be done
          loginState = true;
        }
      } catch (error) {
        console.error('Error parsing user session:', error);
      }
    }

    const datalayerArgs: IDatalayerPageDetails = {
      event: 'GAVirtual',
      virtualPageUrl,
      virtualPageTitle: pageData.name,
      HK_pageCategory: String(pageData?.category),
      HK_pageMidCategory: String(pageData?.midCategory),
      HK_pageSubCategory: String(pageData?.subCategory),
      HK_pageType: String(pageData?.type),
      HK_pageName: String(pageData?.name),
      HK_serverCode: SERVER_CODE,
      HK_botStatus: clientData.HK_botStatus,
      version: VERSION,
      pipelineName: PIPELINE_NAME,
      loginState,
      hkMemberId,
      hkSha256Email,
    };

    if (type === 'cek') {
      datalayerArgs.step = pageData.step ?? 0;
    }

    return datalayerArgs;
  }
}

class CustomRequestEvent extends DataLayerEvent {
  constructor(
    private eventLabel: string,
    private eventAction: string = 'Kobi',
    private eventCategory: string,
    private eventValue?: string
  ) {
    super();
  }

  toDataLayerObject(): IDatalayerCustomEvent {
    return {
      event: 'GAEvent',
      eventCategory: this.eventCategory,
      eventAction: this.eventAction,
      eventLabel: this.eventLabel,
      // eventValue: this.eventValue,
    };
  }
}

class CustomRequestEventLogin extends DataLayerEvent {
  constructor(
    private eventLabel: string,
    private eventAction: string = 'Kobi',
    private eventCategory: string,
    private memberId: string
  ) {
    super();
  }

  toDataLayerObject(): IDatalayerCustomEvent {
    return {
      event: 'login',
      eventCategory: this.eventCategory,
      eventAction: this.eventAction,
      eventLabel: this.eventLabel,
      memberId: this.memberId,
      // eventValue: this.eventValue,
    };
  }
}

class CustomRequestEventSignup extends DataLayerEvent {
  constructor(
    private eventLabel: string,
    private eventAction: string = 'Kobi',
    private eventCategory: string,
    private memberId: string
  ) {
    super();
  }

  toDataLayerObject(): IDatalayerCustomEvent {
    return {
      event: 'sign_up',
      eventCategory: this.eventCategory,
      eventAction: this.eventAction,
      eventLabel: this.eventLabel,
      memberId: this.memberId,
      // eventValue: this.eventValue,
    };
  }
}

class CustomSliderClickEvent extends DataLayerEvent {
  constructor(
    private eventLabel: string,
    private eventAction: string = 'Kobi',
    private eventCategory: string,
    private id: string,
    private name: string,
    private creative: string,
    private position: number | string
  ) {
    super();
  }

  toDataLayerObject(): Slider {
    return {
      event: 'ecInternalPromotionClick',
      eventCategory: this.eventCategory,
      eventAction: this.eventAction,
      eventLabel: this.eventLabel,
      ecommerce: {
        promoClick: {
          promotions: [
            {
              id: this.id,
              name: this.name,
              creative: this.creative,
              position: this.position,
            },
          ],
        },
      },
    };
  }
}

class CustomSliderViewEvent extends DataLayerEvent {
  constructor(
    private eventLabel: string,
    private eventAction: string = 'Kobi',
    private eventCategory: string,
    private id: string,
    private name: string,
    private creative: string,
    private position: number | string
  ) {
    super();
  }

  toDataLayerObject(): Slider {
    return {
      event: 'ecInternalPromotionImpression',
      eventCategory: this.eventCategory,
      eventAction: this.eventAction,
      eventLabel: this.eventLabel,
      ecommerce: {
        promoView: {
          promotions: [
            {
              id: this.id,
              name: this.name,
              creative: this.creative,
              position: this.position,
            },
          ],
        },
      },
    };
  }
}

class PurchaseEvent extends DataLayerEvent {
  constructor(
    private eventLabel: string,
    private actionFields: ActionFieldPurchase,
    private products: PurchaseProduct[]
  ) {
    super();
  }

  toDataLayerObject(): Purchase {
    return {
      event: 'purchase',
      eventCategory: 'Enhanced Ecommerce',
      eventAction: 'Purchase',
      eventLabel: this.eventLabel,
      ecommerce: {
        purchase: {
          actionField: {
            id: this.actionFields.id,
            revenue: String(this.actionFields.revenue ?? 0),
            action: 'purchase',
            currency: 'TRY', // static
          },
          products: this.products,
        },
      },
    };
  }
}

class LoginStateEvent extends DataLayerEvent {
  toDataLayerObject(): IDatalayerUserInfo {
    return {
      loginState: false,
    };
  }
}

interface IDataLayerService {
  sendEvent(eventData: IEventData): void;
}

class DataLayerService implements IDataLayerService {
  sendEvent(eventData: IEventData) {
    eventData.push();
  }
}

export {
  DataLayerService,
  OriginalLocationEvent,
  PageDetailsEvent,
  CustomRequestEvent,
  CustomRequestEventLogin,
  CustomRequestEventSignup,
  PurchaseEvent,
  LoginStateEvent,
  CustomSliderClickEvent,
  CustomSliderViewEvent,
};
