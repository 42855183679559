import classNames from 'classnames';
import { MouseEventHandler } from 'react';

type tagOptions = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';

// const sizeOptions = {
//   h1: 'text-6xl',
//   h2: 'text-5xl',
//   h3: 'text-4xl',
//   h4: 'text-3xl',
//   h5: 'text-2xl',
//   h6: 'text-xl',
//   h7: 'text-lg',
// } as const;

interface IHeading {
  children: React.ReactNode;
  cls?: string;
  tagType: tagOptions;
  //sizeType: keyof typeof sizeOptions;
  bolded?: boolean;
  onclick?: MouseEventHandler<HTMLHeadingElement>;
}

const Heading: React.FC<IHeading> = (props) => {
  const settedClasses = classNames(
    //sizeOptions[iheading.sizeType],
    props.cls,
    props.bolded === true ? 'font-bold' : ''
  );

  return (
    <props.tagType onClick={props.onclick} className={settedClasses}>
      {props.children}
    </props.tagType>
  );
};

export default Heading;
